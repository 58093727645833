import { Heading, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentArea } from "../app/shared";

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Flex flexDirection="column" alignItems="center">
        <Heading size="lg" textAlign="center">
          {t("pages.notFoundPage.content")}
        </Heading>
      </Flex>
    </ContentArea>
  );
};

export default NotFoundPage;
