export const zone1 = [
  { lng: -8.868395059416104, lat: 41.857956964271054 },
  { lng: -8.845049112150479, lat: 41.86511636691542 },
  { lng: -8.826509683439541, lat: 41.87738776360889 },
  { lng: -8.820673196623135, lat: 41.8906791181644 },
  { lng: -8.811746805021572, lat: 41.8998792054351 },
  { lng: -8.777071206877041, lat: 41.91469878063983 },
  { lng: -8.760935037443447, lat: 41.920574556146114 },
  { lng: -8.73964902670126, lat: 41.939730935264585 },
  { lng: -8.739992349455166, lat: 41.95071133126655 },
  { lng: -8.736559121916104, lat: 41.963221570168265 },
  { lng: -8.713556497404385, lat: 41.971900700878905 },
  { lng: -8.69295713217001, lat: 41.980833865019854 },
  { lng: -8.669954507658291, lat: 41.989510596029746 },
  { lng: -8.655534951994229, lat: 42.00609517279561 },
  { lng: -8.653818338224697, lat: 42.02650102614369 },
  { lng: -8.638368814298916, lat: 42.03415153309596 },
  { lng: -8.635622232267666, lat: 42.045115653954895 },
  { lng: -8.599444597074795, lat: 42.05177607098427 },
  { lng: -8.59860012495812, lat: 42.057453168880876 },
  { lng: -8.605766987445913, lat: 42.05643352853142 },
  { lng: -8.620358204486928, lat: 42.05579624499957 },
  { lng: -8.634434437397084, lat: 42.053501971335145 },
  { lng: -8.644219135883413, lat: 42.04763845099798 },
  { lng: -8.64954063856896, lat: 42.04062700970653 },
  { lng: -8.653832172992788, lat: 42.03578228920867 },
  { lng: -8.660183643940053, lat: 42.03208475402405 },
  { lng: -8.663616871479116, lat: 42.02762192509233 },
  { lng: -8.663101887348256, lat: 42.01997063226584 },
  { lng: -8.66224358046349, lat: 42.01461417944029 },
  { lng: -8.669281696918569, lat: 42.00211403502592 },
  { lng: -8.680783009174428, lat: 41.99509757179247 },
  { lng: -8.69348595106896, lat: 41.99241835447608 },
  { lng: -8.703098988178334, lat: 41.98642160229121 },
  { lng: -8.714256977680288, lat: 41.9845076262949 },
  { lng: -8.731079792621694, lat: 41.97876535307176 },
  { lng: -8.745842671039663, lat: 41.97110818324284 },
  { lng: -8.753224110248647, lat: 41.9651094238736 },
  { lng: -8.757687306049428, lat: 41.958854796713965 },
  { lng: -8.753739094379506, lat: 41.95362086139408 },
  { lng: -8.752709126117788, lat: 41.9453222781711 },
  { lng: -8.758717274311147, lat: 41.93957647232816 },
  { lng: -8.768673634174428, lat: 41.93485175520093 },
  { lng: -8.779144978168569, lat: 41.931020646514426 },
  { lng: -8.789272999408803, lat: 41.92489039398565 },
  { lng: -8.800430988910756, lat: 41.92016458894736 },
  { lng: -8.815365528705678, lat: 41.920420046816815 },
  { lng: -8.829956745746694, lat: 41.91479973745212 },
  { lng: -8.841458058002553, lat: 41.9068793705113 },
  { lng: -8.852444386127553, lat: 41.8975525174346 },
  { lng: -8.861542439106069, lat: 41.88707415372705 },
  { lng: -8.867893910053334, lat: 41.87991721023921 },
  { lng: -8.873902058246694, lat: 41.87084207581237 },
  { lng: -8.878880238178334, lat: 41.8620213443248 },
  { lng: -8.877163624408803, lat: 41.8575465950197 },
  { lng: -8.868395059416104, lat: 41.857956964271054 },
];

export const zone2 = [
  { lng: -8.600227460795344, lat: 42.054567703148685 },
  { lng: -8.599540815287531, lat: 42.052145920560804 },
  { lng: -8.553510456200486, lat: 42.05074307625936 },
  { lng: -8.526731281395799, lat: 42.05966517362905 },
  { lng: -8.520551471825486, lat: 42.070370035952905 },
  { lng: -8.48450258266533, lat: 42.076995950946596 },
  { lng: -8.445020465966111, lat: 42.0792893757426 },
  { lng: -8.433004169579393, lat: 42.07240885270196 },
  { lng: -8.417897968407518, lat: 42.0706248916279 },
  { lng: -8.40485170375908, lat: 42.07750560806416 },
  { lng: -8.39386537563408, lat: 42.07546695503565 },
  { lng: -8.387685566063768, lat: 42.0736830799146 },
  { lng: -8.370176105614549, lat: 42.07827008606597 },
  { lng: -8.367772846337205, lat: 42.085914359561585 },
  { lng: -8.354726581688768, lat: 42.08540476998061 },
  { lng: -8.328977375145799, lat: 42.07954419560359 },
  { lng: -8.318334369774705, lat: 42.091774345543215 },
  { lng: -8.319364338036424, lat: 42.099671730998736 },
  { lng: -8.307004718895799, lat: 42.10043594191704 },
  { lng: -8.291211872216111, lat: 42.10807754453791 },
  { lng: -8.277478962059861, lat: 42.11851957858846 },
  { lng: -8.26477602016533, lat: 42.11953822151147 },
  { lng: -8.249326496239549, lat: 42.121575458233195 },
  { lng: -8.250013141747361, lat: 42.132779089390176 },
  { lng: -8.242460041161424, lat: 42.13761640848585 },
  { lng: -8.235593586083299, lat: 42.13023298356834 },
  { lng: -8.218770771141893, lat: 42.13201526838999 },
  { lng: -8.208127765770799, lat: 42.137870993992955 },
  { lng: -8.197828083153611, lat: 42.148817202569 },
  { lng: -8.197141437645799, lat: 42.152380676048345 },
  { lng: -8.196798114891893, lat: 42.15670748128533 },
  { lng: -8.204007892723924, lat: 42.15619846072831 },
  { lng: -8.205037860985643, lat: 42.148817202569 },
  { lng: -8.209157734032518, lat: 42.144235299035245 },
  { lng: -8.215680866356736, lat: 42.14168965378061 },
  { lng: -8.226667194481736, lat: 42.138125578476554 },
  { lng: -8.23456361782158, lat: 42.138125578476554 },
  { lng: -8.244176654930955, lat: 42.142962489201715 },
  { lng: -8.255162983055955, lat: 42.13863474437332 },
  { lng: -8.260312824364549, lat: 42.13099682606124 },
  { lng: -8.261342792626268, lat: 42.12335798663482 },
  { lng: -8.273702411766893, lat: 42.12717752148577 },
  { lng: -8.282972126122361, lat: 42.123867271253 },
  { lng: -8.286062030907518, lat: 42.11928356231585 },
  { lng: -8.301168232079393, lat: 42.112152689334295 },
  { lng: -8.309407978173143, lat: 42.10654929769617 },
  { lng: -8.31970766079033, lat: 42.107313425722246 },
  { lng: -8.328290729637986, lat: 42.102728519411066 },
  { lng: -8.331037311669236, lat: 42.094831514637484 },
  { lng: -8.334127216454393, lat: 42.08846224606862 },
  { lng: -8.343396930809861, lat: 42.08718831560642 },
  { lng: -8.351979999657518, lat: 42.0912648030344 },
  { lng: -8.366742878075486, lat: 42.09406723617883 },
  { lng: -8.376699237938768, lat: 42.08642394504947 },
  { lng: -8.381849079247361, lat: 42.079034554858396 },
  { lng: -8.38837221157158, lat: 42.079289375742626 },
  { lng: -8.39386537563408, lat: 42.08183752830501 },
  { lng: -8.404165058251268, lat: 42.083621174213405 },
  { lng: -8.41309144985283, lat: 42.08005383225575 },
  { lng: -8.418241291161424, lat: 42.07699595094664 },
  { lng: -8.427511005516893, lat: 42.07495728154597 },
  { lng: -8.431630878563768, lat: 42.079289375742626 },
  { lng: -8.44055727016533, lat: 42.083875976678605 },
  { lng: -8.451200275536424, lat: 42.08464037793443 },
  { lng: -8.478666095848924, lat: 42.08311156621315 },
  { lng: -8.488622455712205, lat: 42.08413077812049 },
  { lng: -8.500638752098924, lat: 42.08285676067808 },
  { lng: -8.510595111962205, lat: 42.08030864904691 },
  { lng: -8.522611408348924, lat: 42.082347146538105 },
  { lng: -8.533597736473924, lat: 42.07979901444132 },
  { lng: -8.533597736473924, lat: 42.067821422925064 },
  { lng: -8.53394105922783, lat: 42.06221411408765 },
  { lng: -8.548360614891893, lat: 42.05839066502971 },
  { lng: -8.560033588524705, lat: 42.05635139806454 },
  { lng: -8.581662922020799, lat: 42.05533174002538 },
  { lng: -8.592305927391893, lat: 42.05890047153907 },
  { lng: -8.599172382470018, lat: 42.05737103973268 },
  { lng: -8.600227460795344, lat: 42.054567703148685 },
];
