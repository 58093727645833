import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { MainLayout, NavbarArea } from "../app/shared";
import { Navbar } from "app/navbar";
import { theme } from "../styles";
import { StoreProvider } from "store";
import NotFoundPage from "./NotFoundPage";
import TagViewDetailsPage from "./TagViewDetailsPage";

const App = () => (
  <ChakraProvider theme={theme} resetCSS={true}>
    <StoreProvider>
      <BrowserRouter>
        <MainLayout>
          <NavbarArea>
            <Navbar />
          </NavbarArea>
          <Switch>
            <Route path="/404" component={NotFoundPage} />
            <Route path="/:tagTypeId" component={TagViewDetailsPage} />
            <Redirect from="*" to="/404" />
          </Switch>
        </MainLayout>
      </BrowserRouter>
    </StoreProvider>
  </ChakraProvider>
);

export default App;
