import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ClassNames } from "@emotion/react";
import { tableStyle } from "app/shared/tableStyle";
import { environment } from "configurations";
import GoogleMapReact from "google-map-react";
import { observer } from "mobx-react-lite";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useStoreContext } from "store";
import {
  NonConformityDetectedEvent,
  TagViewInformation,
  TradeUnit,
  TradeUnitRejectedEvent,
} from "../models";
import { Marker } from "./Marker";
import { zone1, zone2 } from "./Zones";

interface Props {
  id: string;
}

const TagViewDetails: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { tagStore } = useStoreContext();
  const history = useHistory();
  const [information, setInformation] =
    useState<TagViewInformation | undefined>();
  useEffect(() => {
    const createTagView = (latitude?: number, longitude?: number) => {
      tagStore
        .createTagView(id, latitude, longitude)
        .then((information) => {
          if (information.tradeUnit?.tagId === id) {
            setInformation(information);
          } else {
            history.push("/");
          }
        })
        .catch(() => history.push("/"));
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          createTagView(position.coords.latitude, position.coords.longitude);
        },
        () => {
          createTagView();
        }
      );
    } else {
      createTagView();
    }
  }, [tagStore, history, id]);

  const rowClassName = (tradeUnit: TradeUnit) => {
    return tradeUnit.status === "Rejected"
      ? { "column-datagrid-odd": true }
      : { "column-datagrid": true };
  };

  // Draws the areas for the capture areas
  const drawCaptureAreas = useCallback((map: any, maps: any) => {
    const zone1Poly = new maps.Polygon({
      paths: zone1,
      strokeColor: "#333333",
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: "#00FF00",
      fillOpacity: 0.5,
    });
    zone1Poly.setMap(map);

    const zone2Poly = new maps.Polygon({
      paths: zone2,
      strokeColor: "#333333",
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: "#FFFF00",
      fillOpacity: 0.5,
    });

    zone2Poly.setMap(map);
  }, []);

  // Prevent initiating form without trade unit initial values
  if (information?.tradeUnit?.tagId !== id) {
    return <Spinner />;
  }

  const rejections =
    information.tradeUnit?.events?.filter(
      (it) =>
        it.type === "NonConformityDetected" || it.type === "TradeUnitRejected"
    ) ?? [];

  return (
    <Box>
      {tagStore.state === "pending" && <Spinner mb={4} />}
      {tagStore.error?.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {tagStore.error}
        </Alert>
      )}
      <Tabs variant="enclosed">
        <TabList>
          <Tab>{t("tags.tagViewDetails.details")}</Tab>
          <Tab>{t("tags.tagViewDetails.history")}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex flexDirection={["column", null, "row"]}>
              <Box width={["100%", null, "50%"]}>
                <Heading size="lg">
                  {t("tags.tagViewDetails.tagInformation")}
                </Heading>
                <Text>
                  <b>{t("tags.tagViewDetails.status")}:</b>{" "}
                  {information.tradeUnit.status}
                </Text>
                <Text>
                  <b>{t("tags.tagViewDetails.tagNumber")}:</b>{" "}
                  {information.tradeUnit.tagNumber}
                </Text>
                <Text>
                  <b>{t("tags.tagViewDetails.tagId")}:</b>{" "}
                  {information.tradeUnit.tagId}
                </Text>

                {rejections.length > 0 && (
                  <>
                    <Heading size="lg" mt={4}>
                      {t("tags.tagViewDetails.nonConformities")}
                    </Heading>
                    {rejections.map((it, idx) => {
                      let text = "";

                      if (it.type === "NonConformityDetected") {
                        text = (it as NonConformityDetectedEvent).evidences
                          .map((e) => e.comment)
                          .join(",");
                      } else if (it.type === "TradeUnitRejected") {
                        text = (it as TradeUnitRejectedEvent).comment;
                      }

                      return (
                        <Text key={idx}>
                          <b>{it.type}:</b> {text}
                        </Text>
                      );
                    })}
                  </>
                )}

                <Heading size="lg" mt={4}>
                  {t("tags.tagViewDetails.captureInformation")}
                </Heading>
                {information.fisher && (
                  <Flex alignItems="center" my={4}>
                    <Avatar src={information.fisher.photo} size="lg" mr={3} />
                    <Box>
                      {information.fisher.name} (
                      {information.fisher.foodBusinessId})
                    </Box>
                  </Flex>
                )}
                <Text>
                  <b>{t("tags.tagViewDetails.date")}:</b>{" "}
                  {information.tradeUnit.originInfo.dateOfCapture}
                </Text>
                <Text>
                  <b>{t("tags.tagViewDetails.fishSpecies")}:</b>{" "}
                  {information.tradeUnit.originInfo.fishSpecie.commonName}
                </Text>
                <Text>
                  <b>{t("tags.tagViewDetails.netWeight")}:</b>{" "}
                  {information.tradeUnit.originInfo.netWeight}
                </Text>
                <Text>
                  <b>{t("tags.tagViewDetails.fishingMethod")}:</b>{" "}
                  {information.tradeUnit.originInfo.fishingMethod}
                </Text>

                <Heading size="lg" mt={4}>
                  {t("tags.tagViewDetails.certificationInformation")}
                </Heading>
                <Text>
                  <b>{t("tags.tagViewDetails.by")}:</b>{" "}
                  {information.tradeUnit.originInfo.principal.name} (
                  {information.tradeUnit.originInfo.principal.email})
                </Text>
                <Text>
                  <b>{t("tags.tagViewDetails.date")}:</b>{" "}
                  {information.tradeUnit.originInfo.date}
                </Text>
                {information.tradeUnit.originInfo.tagLocation && (
                  <Text>
                    <b>{t("tags.tagViewDetails.location")}:</b>{" "}
                    {information.tradeUnit.originInfo.tagLocation.latitude},{" "}
                    {information.tradeUnit.originInfo.tagLocation.longitude}
                  </Text>
                )}
              </Box>
              <Box width={["100%", null, "50%"]}>
                <Box height="450px" mt={[7, null, 0]}>
                  <GoogleMapReact
                    onGoogleApiLoaded={(args: { map: any; maps: any }) => {
                      console.log(args);
                      drawCaptureAreas(args.map, args.maps);
                    }}
                    bootstrapURLKeys={{ key: environment.googleMapsKey }}
                    defaultCenter={{
                      lat:
                        information.tradeUnit.originInfo.tagLocation
                          ?.latitude ?? 41.688231,
                      lng:
                        information.tradeUnit.originInfo.tagLocation
                          ?.longitude ?? -8.790941,
                    }}
                    defaultZoom={9}>
                    {information.tradeUnit.originInfo.tagLocation && (
                      <Marker
                        lat={
                          information.tradeUnit.originInfo.tagLocation.latitude
                        }
                        lng={
                          information.tradeUnit.originInfo.tagLocation.longitude
                        }>
                        X
                      </Marker>
                    )}
                    {information.tagViews
                      .filter((it) => it.latitude && it.longitude)
                      .map((it, idx) => (
                        <Marker
                          key={idx}
                          lat={it.latitude!!}
                          lng={it.longitude!!}
                          backgroundColor="red">
                          X
                        </Marker>
                      ))}
                  </GoogleMapReact>
                </Box>
                <Box mt={3}>
                  <Flex alignItems="center">
                    <Marker
                      lat={0}
                      lng={0}
                      backgroundColor="blue"
                      width="20px"
                      height="20px"
                      mr={1}>
                      X
                    </Marker>
                    {t("tags.tagViewDetails.certificationLocation")}
                  </Flex>
                  <Flex alignItems="center">
                    <Marker
                      lat={0}
                      lng={0}
                      backgroundColor="red"
                      width="20px"
                      height="20px"
                      mr={1}>
                      X
                    </Marker>
                    {t("tags.tagViewDetails.viewLocation")}
                  </Flex>
                  <Flex alignItems="center">
                    <Box
                      backgroundColor="#00FF00"
                      width="20px"
                      height="20px"
                      mr={1}></Box>
                    {t("tags.tagViewDetails.zone1")}
                  </Flex>
                  <Flex alignItems="center">
                    <Box
                      backgroundColor="#FFFF00"
                      width="20px"
                      height="20px"
                      mr={1}></Box>
                    {t("tags.tagViewDetails.zone2")}
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </TabPanel>

          <TabPanel>
            <ClassNames>
              {({ css }) => (
                <DataTable
                  rowClassName={rowClassName}
                  totalRecords={information.versionHistory.length}
                  rows={information.versionHistory.length}
                  value={information.versionHistory}
                  emptyMessage={t("tags.tagViewDetails.noResults")}
                  loading={tagStore.state === "pending"}
                  className={css`
                    ${tableStyle}
                  `}>
                  <Column
                    header={t("tags.tagViewDetails.history")}
                    body={(tradeUnit: TradeUnit) => (
                      <Box textAlign="left" sx={{ overflow: "hidden" }}>
                        <pre>{JSON.stringify(tradeUnit, null, 2)}</pre>
                      </Box>
                    )}
                  />
                </DataTable>
              )}
            </ClassNames>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default observer(TagViewDetails);
