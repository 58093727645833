import { makeAutoObservable, runInAction } from "mobx";
import { TagViewInformation } from "../models";
import { createTagView } from "../services";

export default class TagStore {
  state: "pending" | "done" | "error" = "done";
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = err.message;
      });
      throw err;
    }
  }

  async createTagView(
    tagId: string,
    latitude?: number,
    longitude?: number
  ): Promise<TagViewInformation> {
    return this.wrapRequest(async () => {
      return await createTagView(tagId, latitude, longitude);
    });
  }
}
