import React from "react";
import { StoreContext } from "./context";
import TagStore from "app/tags/stores/TagStore";

export const store = {
  tagStore: new TagStore(),
};

interface Props {
  children: React.ReactNode;
}

export const StoreProvider: React.FC<Props> = ({ children }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
