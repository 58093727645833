import React, { useContext } from "react";
import TagStore from "app/tags/stores/TagStore";

interface StoreContextProps {
  tagStore: TagStore;
}

export const StoreContext = React.createContext<StoreContextProps>(
  {} as StoreContextProps
);

export const useStoreContext = () => useContext(StoreContext);
