import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import { Image } from "app/shared";
import LogoImage from "./static/logo.png";

export const Navbar: React.FC = () => {
  return (
    <Box width="100%">
      <Flex
        height="100%"
        maxWidth="maxWidth"
        mx="auto"
        px={2}
        alignItems="center"
        justifyContent="center">
        <Image src={LogoImage} maxHeight={["40px", null, "70px"]} />
      </Flex>
    </Box>
  );
};
