import React from "react";
import { ContentArea } from "app/shared";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { TagViewDetails } from "app/tags";
import queryString from "querystring";

interface Params {
  tagTypeId: string;
}

const TagViewDetailsPage: React.FC<RouteComponentProps<Params>> = ({
  match,
}) => {
  const location = useLocation();
  const search = queryString.parse(location.search);
  const tagId = `${match.params.tagTypeId}+${
    search?.["?uid"] ?? search?.["uid"]
  }`;

  return (
    <ContentArea>
      <TagViewDetails id={tagId} />
    </ContentArea>
  );
};

export default TagViewDetailsPage;
