import axios from "network";
import { TagViewInformation } from "../models";

export const createTagView = async (
  tagId: string,
  latitude?: number,
  longitude?: number
): Promise<TagViewInformation> => {
  const response = await axios.post(`/tags/${tagId}/view`, {
    latitude,
    longitude,
  });
  return response.data;
};
